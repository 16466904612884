import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DetailsContainer } from "./commonComponents";

export const NotableProjectsContainer = styled((props) => (<DetailsContainer
  {...props}
/>))(({ theme }) => ({
  margin: '0',
  background: 'linear-gradient(rgb(20, 24, 35), rgb(0, 0, 0))',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    padding: '5vh 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15vh 0px',
  },
}));

export const NotableProjectsBox = styled((props) => (<Box
  component='ul'
  {...props}
/>))(({ theme }) => ({
  listStyle: 'none',
  margin: '0px auto',
  width: '85%',
  position: 'relative',
  display: 'block',
  padding: '0',

  '& > li:not(:last-child)': {
    margin: '0 0 10% 0',
  },
}));

export const Featured = styled((props) => (<li
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  top: '0px',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
  },
  [theme.breakpoints.up('md')]: {
    '&.left': {
      justifyContent: 'flex-start',
    },
    '&.right': {
      justifyContent: 'flex-end',
    },
  },
}));

export const FeaturedDescriptionSection = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  zIndex: '1',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.6)',

  '& *': {
    zIndex: '2',
  },

  [theme.breakpoints.up('xs')]: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '4vh 2vw',
    backgroundSize: 'cover',
    borderTop: 'none',
    width: '100%',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#171c28',
    },
  },
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.background.featured,
    color: theme.palette.text.primary,
    padding: '2vh 2vw',
    width: '50%',

    '&::before': {
      backgroundColor: 'transparent',
      width: '0px',
      height: '0px',
    },

    '&.left': {
      order: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      borderRadius: '0px 5px 5px 0px',
    },

    '&.right': {
      order: 0,
      left: '0px',
      borderRadius: '5px 0px 0px 5px',
    },
  },
}));

export const FeaturedBodyText = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  marginBottom: '5px',
}));

export const FeaturedSkillArea = styled((props) => (<Box
  component="ul"
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  listStyle: 'none',
  padding: '0',
  margin: '0',

  '& li:not(:last-child)': {
    marginRight: '10px',
  },
}));

export const FeaturedSkill = styled((props) => (<Box
  component="li"
  {...props}
/>))(({ theme }) => ({
  fontSize: '0.8rem',
  '&::before': {
    content: '"▸ "',
  },
}));

export const LinkList = styled((props) => (<List
  {...props}
/>))(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  padding: '0',
}));

export const LinkItem = styled((props) => (<ListItem
  {...props}
/>))(({ theme }) => ({
  width: 'fit-content',
  color: 'black',
  padding: '0',
}));

export const LinkItemButton = styled((props) => (<ListItemButton
  {...props}
/>))(({ theme }) => ({
  width: 'fit-content',
  fontSize: '1rem',

  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  svg: {
    color: 'inherit',
    paddingRight: '5px',
    fontSize: '1.6rem',
  },
  div: {
    color: 'inherit',
    fontSize: 'inherit',
    minWidth: 'fit-content',
  },

  [theme.breakpoints.up('xs')]: {
    color: 'rgba(83, 215, 255, 1)',
  },
  [theme.breakpoints.up('md')]: {
    color: 'rgba(0, 122, 159, 1)',
  },
}));

export const FeaturedPictureSection = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  backgroundColor: 'lightblue',
  height: 'fit-content',
  lineHeight: '0',

  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    position: 'relative',
    width: '50%',
    pointerEvents: 'initial',

    '::before': {
      content: '""',
      lineHeight: '0',
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0, 0.3)',
    },
  },
}));

export const FeaturedImage = styled('img')(({ theme }) => ({
  width: '100%',
}));

export const FeaturedTitle = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
}));

export const FeaturedNumber = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  // yellow font color
  color: 'rgba(150, 141, 0,1)',
  textDecoration: 'underline',
  textUnderlineOffset: '2px',
}));